var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[(_vm.id)?_c('span',[_vm._v("编辑字段")]):_vm._e(),(!_vm.id)?_c('span',[_vm._v("添加字段")]):_vm._e()])]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"字段类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'itemType',
          {
            rules: [
              {
                required: true,
                message: _vm.l('validation.required'),
              } ],
          } ]),expression:"[\n          'itemType',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('validation.required'),\n              },\n            ],\n          },\n        ]"}]},_vm._l((_vm.itemTypeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code},on:{"click":function($event){return _vm.handleChange(item.code)}}},[_vm._v(_vm._s(item.text))])}),1)],1),_c('a-form-item',{attrs:{"label":"字段是否必填","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'isRequire',
          {
            rules: [
              {
                required: true,
                message: _vm.l('validation.required'),
              } ],
          } ]),expression:"[\n          'isRequire',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('validation.required'),\n              },\n            ],\n          },\n        ]"}]},_vm._l((_vm.requireList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(_vm._s(item.text))])}),1)],1),_c('a-form-item',{attrs:{"label":"字段标题","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              {
                required: true,
                message: _vm.l('validation.required'),
              } ],
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('validation.required'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请输入字段标题"}})],1),_c('a-form-item',{attrs:{"label":"字段标识","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'key',
          {
            rules: [
              {
                required: true,
                message: _vm.l('validation.required'),
              },
              {
                validator: _vm.checkData,
              } ],
          } ]),expression:"[\n          'key',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('validation.required'),\n              },\n              {\n                validator: checkData,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请输入字段标识"}})],1),_c('a-form-item',{attrs:{"label":"字段最大长度","has-feedback":""}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'maxLength',
          {
            rules: [
              {
                required: true,
                message: _vm.l('validation.required'),
              } ],
          } ]),expression:"[\n          'maxLength',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('validation.required'),\n              },\n            ],\n          },\n        ]"}],attrs:{"min":0,"placeholder":"请输入字段最大长度"}})],1),(_vm.optionsShow)?_c('a-form-item',{attrs:{"label":"字段选项内容"}},[_c('div',[_vm._l((_vm.tags),function(tag,index){return [(tag.length > 20)?_c('a-tooltip',{key:tag,attrs:{"title":tag}},[_c('a-tag',{key:tag,attrs:{"closable":index !== -1},on:{"close":function () { return _vm.handleClose(tag); }}},[_vm._v(" "+_vm._s(((tag.slice(0, 20)) + "..."))+" ")])],1):_c('a-tag',{key:tag,attrs:{"color":"blue","closable":index !== -1},on:{"close":function () { return _vm.handleClose(tag); }}},[_vm._v(" "+_vm._s(tag)+" ")])]}),(_vm.inputVisible)?_c('a-input',{ref:"input",style:({ width: '78px' }),attrs:{"type":"text","size":"small","value":_vm.inputValue},on:{"change":_vm.handleInputChange,"blur":_vm.handleInputConfirm,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleInputConfirm.apply(null, arguments)}}}):_c('a-tag',{staticStyle:{"background":"#fff","borderstyle":"dashed"},on:{"click":_vm.showInput}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 添加值 ")],1)],2)]):_vm._e(),_c('a-form-item',{attrs:{"label":"字段描述","has-feedback":""}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
          {
            rules: [
              {
                required: true,
                message: _vm.l('validation.required'),
              } ],
          } ]),expression:"[\n          'remark',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('validation.required'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请输入字段描述","auto-size":{ minRows: 3, maxRows: 5 }}})],1),_c('a-form-item',{staticClass:"btn--container"},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l("Save"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }