<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="id">{{ l("Edit") }}</span>
        <span v-if="!id">{{ l("Create") }}</span>
      </div>
    </div>

    <a-form-model
      :model="form"
      @submit="handleSubmit"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="ruleForm"
      :rules="rules"
    >
      <a-form-model-item ref="formName" prop="formName" label="表单名称">
        <a-input v-model="form.formName" placeholder="请输入表单名称" />
      </a-form-model-item>
      <a-form-model-item
        ref="headerRemark"
        prop="headerRemark"
        label="顶部描述"
      >
        <a-input
          v-model="form.headerRemark"
          type="textarea"
          placeholder="请输入顶部描述"
        />
      </a-form-model-item>
      <a-form-model-item
        ref="footerRemark"
        prop="footerRemark"
        label="底部描述"
      >
        <a-input
          v-model="form.footerRemark"
          type="textarea"
          placeholder="请输入底部描述"
        />
      </a-form-model-item>
      <a-form-model-item
        ref="projectId"
        prop="projectId"
        label="关联项目"
        v-if="id === undefined"
      >
        <a-select v-model="form.projectId" placeholder="请选择关联项目">
          <a-select-option
            :value="item.value"
            v-for="(item, index) in projectData"
            :key="index"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="formStatus" prop="formStatus" label="状态">
        <a-switch v-model="form.formStatus" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="button" @click="close()">
          {{ l("Cancel") }}
        </a-button>
        <a-button type="primary" html-type="submit">
          {{ l("Save") }}
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CollectionFormServiceProxy } from "./services/collectionform-proxies";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-colletionform",
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      // 获取到的数据
      entity: {},
      spinning: false,
      _serviceProxy: "",
      commonService: null,
      labelCol: {
        span: 5,
      },
      form: {
        formName: "",
        headerRemark: "",
        footerRemark: "",
        projectId: undefined,
        formStatus: true,
      },
      formStatusList: [
        { code: 1, text: "有效" },
        { code: 0, text: "禁用" },
      ],
      formTypeList: [
        { code: 0, text: "自然人" },
        { code: 1, text: "非自然人类型" },
        { code: 2, text: "执行记录" },
        { code: 3, text: "系统表单" },
      ],
      isDisabled: false,
      projectData: [],
      rules: {
        formName: [
          {
            required: true,
            message: "请输入表单名称",
            trigger: "blur",
          },
          {
            min: 3,
            max: 50,
            message: "名称字数在3-50之前",
            trigger: "blur",
          },
        ],
        headerRemark: [
          {
            required: true,
            message: "请输入顶部描述",
            trigger: "blur",
          },
        ],
        footerRemark: [
          {
            required: true,
            message: "请输入底部描述",
            trigger: "blur",
          },
        ],
        projectId: [
          {
            required: true,
            message: "请选择关联项目",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this._serviceProxy = new CollectionFormServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this._getProject();
  },
  async mounted() {
    console.log(this.id);
    if (this.id) {
      await this.getData();
    }
  },
  methods: {
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      // var res = await this._serviceProxy.getForEdit(this.id);
      try {
        let res = await this.commonService.get({
          params: { id: this.id },
          url: "/api/services/app/Form/GetEditById",
        });
        // res.formStatus = res.formStatus==0?"禁用":"有效"
        let arr = [];
        arr.push(res.startTime);
        arr.push(res.endTime);
        res.time = arr;
        if (res.formType !== 0) {
          this.isDisabled = true;
        }
        this.form = {
          ...res,
          id: this.id,
        };
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    _getProject() {
      let _that = this;
      this._serviceProxy.getProject().then((res) => {
        _that.projectData.push(...res);
      });
    },
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // alert("submit!");
          // console.log(this.form);
          try {
            this.spinning = true;
            let res = await this._serviceProxy.createOrUpdate(this.form);
            this.spinning = false;
            console.log(res);
            if (res.status == 200) {
              this.$notification["success"]({
                message: this.l("SavedSuccessfully"),
              });
              this.success(true);
            }
          } catch {
            this.spinning = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // this.form.validateFieldsAndScroll(async (err, values) => {
      //   console.log(err);
      //   console.log(values);
      //   console.log(values["time"]);
      //   if (!err) {
      //     try {
      //       let res = await this._serviceProxy.createOrUpdate({
      //         ...values,
      //         id: this.id,
      //         formType: 1,
      //       });
      //       this.spinning = false;
      //       console.log(res);
      //       if (res.status == 200) {
      //         this.$notification["success"]({
      //           message: this.l("SavedSuccessfully"),
      //         });
      //         this.success(true);
      //       }
      //     } catch {
      //       this.spinning = false;
      //     }
      //   }
      // });
    },
    changeFormType(item) {
      // 自然人之外
      if (item !== 0) {
        this.form.setFieldsValue({
          isPublic: false,
        });
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
  display: block;
  margin: 10px auto;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
