<template>
  <a-spin :spinning="spinning">
    <!-- <page-header :title="l('CollectionFormManage')"></page-header> -->
    <!-- <page-header title="项目表单管理"></page-header> -->
    <!-- <a-card :bordered="false"> -->
    <!-- table -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :scroll="{ x: 1000 }"
      :columns="columns"
      :hide-row-selection="true"
      :is-full="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <!-- 按钮 -->
      <a-row :gutter="8">
      <a-col :span="5">
            <a-input
              v-model="projectName"
              placeholder="请选择项目"
              @click="selectProjectModal"
            >
            </a-input>
            <a-input v-model="projectIds" type="hidden">
            </a-input>
          </a-col>
        <a-col :span="5">
          <a-input
            v-model.trim="formName"
            :placeholder="l('表单名称')"
          />
        </a-col>
        <a-col :span="4">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.UnnaturalProjectFormPermissions.Query')"
          >
            <a-icon type="search" />
            <span>{{ l("Search") }}</span>
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col :span="10" style="text-align: right">
          <a-button
            type="primary"
            @click="createOrEdit()"
            v-if="isGranted('Pages.UnnaturalProjectFormPermissions.Create')"
          >
            <a-icon type="plus" />
            {{ l("Create") }}
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
    <!-- </a-card> -->
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CollectionFormServiceProxy } from "./services/collectionform-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import EditForm from "./edit.vue";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";
// import { ListTable } from "./components/index";
import EleTable from "@/components/ele-table";
import FieldManageForm from "./FieldManage.vue";
import SelectPageList from "@/components/linggong/select-page-list";

export default {
  mixins: [AppComponentBase],
  name: "bannerads",
  components: { EleTable },
  data() {
    return {
      spinning: false,
      // 搜索
      formName: "",
      projectName:"",
      projectIds:"",
      formType: -1,
      // 选择多少项
      selectedRowKeys: [],
      tableData: [],
      tableKey: "CollectionFormPagedDto",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: {
        sorting: "",
        maxResultCount: 50,
        skipCount: 0,
      },
      // 用户表格
      columns: [
        {
          title: this.l("表单名称"),
          dataIndex: "formName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "formName" },
        },
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          sorter: false,
          align: "center",
          // width: 250,
          scopedSlots: { customRender: "projectName" },
        },
        // {
        //   title: this.l("表单类型"),
        //   dataIndex: "formType",
        //   sorter: false,
        //   align: "center",
        //   scopedSlots: { customRender: "formType" },
        //   customRender: (text, record, index) => {
        //     switch (text) {
        //       case 0:
        //         return "自然人";
        //       case 1:
        //         return "非自然人类型";
        //       case 2:
        //         return "执行记录";
        //       case 3:
        //         return "系统表单";
        //     }
        //     return "";
        //   },
        // },
        {
          title: this.l("创建时间"),
          dataIndex: "creationTime",
          sorter: true,
          align: "center",
          // width: 210,
          scopedSlots: { customRender: "creationTime" },
          customRender: (text) => {
            if (text) {
              return text.replaceAll("T", " ").split(".")[0];
            } else {
              return "-";
            }
          },
        },
        // {
        //   title: this.l("收集结束时间"),
        //   dataIndex: "endTime",
        //   sorter: false,
        //   align: "center",
        //   scopedSlots: { customRender: "endTime" },
        // },
        // {
        //   title: this.l("周期"),
        //   dataIndex: "cycleType",
        //   sorter: false,
        //   align: "center",
        //   scopedSlots: { customRender: "cycleType" },
        // },
        // {
        //   title: this.l("频次"),
        //   dataIndex: "frequency",
        //   sorter: false,
        //   align: "center",
        //   scopedSlots: { customRender: "frequency" },
        // },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ],
      _serviceProxy: "",
      commonService: null,
      actionsType: [],
      formTypeList: [
        { code: -1, text: "请选择表单类型" },
        { code: 0, text: "自然人" },
        { code: 1, text: "非自然人类型" },
        { code: 2, text: "执行记录" },
        { code: 3, text: "系统表单" },
      ],
    };
  },
  created() {
    this._serviceProxy = new CollectionFormServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.getData();
    this.initActionsType();
  },
  methods: {
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/UnnaturalPersonProject/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        console.log(res);
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }
          this.projectIds = projectIds; //data.id;
          this.projectName = projectNames; //data.name;
          this.getData();
          // this.getTaskByProjectId(0, data.id);
        }
      });
    },
    search() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 获取数据
     */
    async getData() {
      // /api/services/app/UnnaturalPersonProjectForm/GetPaged
      // this.spinning = true;
      // var response = await this._serviceProxy.getPaged(
      //   this.projectIds,
      //   this.formName,
      //   this.formType > -1 ? this.formType : undefined,
      //   this.request.sorting,
      //   this.request.maxResultCount,
      //   this.request.skipCount
      // );
      // this.spinning = false;
      // console.log(response.items);
      // this.tableData = response.items;
      // this.tableData.map((item) => {
      //   item.startTime = item.startTime
      //     ? moment(item.startTime).format("YYYY-MM-DD")
      //     : "-";
      //   item.endTime = item.endTime
      //     ? moment(item.endTime).format("YYYY-MM-DD")
      //     : "-";
      //   item.formStatus = item.formStatus == 1 ? "有效" : "禁用";
      //   item.creationTime = moment(item.creationTime).format(
      //     "YYYY-MM-DD hh:mm:ss"
      //   );
      // });
      // this.totalItems = response.totalCount;
      // this.totalPages = Math.ceil(
      //   response.totalCount / this.request.maxResultCount
      // );
      // this.pagerange = [
      //   (this.pageNumber - 1) * this.request.maxResultCount + 1,
      //   this.pageNumber * this.request.maxResultCount,
      // ];
      this.spinning = true;
      let options = {
        url:
          this.$apiUrl + "/api/services/app/UnnaturalPersonProjectForm/GetPaged",
        method: "Post",
         headers: {
          "Content-Type": "application/json-patch+json",
        },
        data: {
        projectIds:this.projectIds,
        formName:this.formName,
        formType:this.formType > -1 ? this.formType : undefined,
        sorting:this.request.sorting,
         maxResultCount: this.request.maxResultCount,
        skipCount: this.request.skipCount         
        },
      };
      this.$api.request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.request.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.request.MaxResultCount + 1,
              this.pageNumber * this.request.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
                startTime : item.startTime? moment(item.startTime).format("YYYY-MM-DD"): "-",
                endTime : item.endTime? moment(item.endTime).format("YYYY-MM-DD"): "-",
                formStatus:item.formStatus == 1 ? "有效" : "禁用",
                creationTime : moment(item.creationTime).format("YYYY-MM-DD hh:mm:ss")
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * table选择事件
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * table选择事件
     */

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      // console.log(this.selectedRows);
    },
    /**
     * 分页
     */
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.formName = "";
      this.projectIds="";
      this.projectName="";
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 新建修改
     */
    createOrEdit(id) {
      // console.log(id);
      ModalHelper.create(
        EditForm,
        {
          id: id,
        },
        {
          width: "650px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },

    /**
     * 清空
     */
    restCheckStatus() {
      this.selectedRowKeys = [];
    },
    /**
     * 单个删除
     */
    deleteItem(id) {
      this.spinning = true;
      this._serviceProxy
        .deleteCollectionForm(id)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    /**
     * 导出为excel
     */
    exportToExcel() {
      this.spinning = true;
      this._serviceProxy.getToExcelFile().then((result) => {
        this.spinning = false;
        fileDownloadService.downloadTempFile(result);
      });
    },
    /**
     * emit新建修改
     */
    // edit(newV) {
    //   console.log(newV);
    //   let { id } = newV;
    //   this.createOrEdit(id);
    // },
    //模板字段管理
    // fieldManage(newV) {
    //   console.log(newV);
    //   let { id } = newV;
    //   this.fieldManageForm(id);
    // },
    fieldManageForm(id) {
      ModalHelper.create(
        FieldManageForm,
        {
          id: id,
        },
        {
          width: "400px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    /**
     * 排序更新data
     */
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    /**
     * 分页
     */
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    /**
     * table选择事件
     */
    updateSelectChange(newV) {
      console.log(newV);
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },

    /**
     * 初始化需要显示的func btn
     */
    initActionsType() {
      let _this = this;
      let obj = {
        type: "CollectionForm",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectFormPermissions.Edit"
              ),
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data.collectionFormId);
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectFormPermissions.FormDesign"
              ),
              name: "表单设计",
              icon: "profile",
              fn: (data) => {
                this.$router.push({
                  path: `/app/fs/collectionformEdit/${data.collectionFormId}?from=2`,
                });
              },
            },
            {
              granted: this.isGranted(
                "Pages.UnnaturalProjectFormPermissions.FormDesignV2"
              ),
              name: "表单设计New",
              icon: "profile",
              fn: (data) => {
                this.$router.push({
                  path: `/app/fs/newCollectionformEdit/${data.collectionFormId}?from=2`,
                });
              },
            },
            // {
            //   granted: this.isGranted("Pages.LGModule.CustomForm.FieldManage"),
            //   name: this.l("模板字段管理"),
            //   icon: "profile",
            //   fn: (data) => {
            //     _this.fieldManageForm(data.id);
            //   },
            // },
          ],
          // delete: {
          //   granted: this.isGranted("Pages.LGModul.CustomForm.Delete"),
          //   name: this.l("Delete"),
          //   fn: (data) => {
          //     _this.deleteItem(data.id);
          //   },
          // },
        },
      };
      this.actionsType = obj;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  // margin-bottom: 20px;
}

.pagination {
  margin: 10px auto;
  text-align: right;
}

.table-plus,
.table-delete {
  margin-left: 10px;
}
</style>
