<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>模板字段管理</span>
      </div>
    </div>
    <a-card :bordered="false">
      <!-- 按钮 -->
      <a-row :gutter="8" class="btn--container">
        <a-col class="gutter-row" :span="9">
          <a-input
            v-model="filterText"
            :placeholder="l('SearchWithThreeDot')"
          />
        </a-col>
        <a-col class="gutter-row" :span="9">
          <a-button type="primary" @click="getData">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" :span="6" style="float: right">
          <a-button
            type="primary"
            @click="createOrEdit(null)"
          >
            <a-icon type="plus" />
            {{ l("Create") }}
          </a-button>
          <!-- <a-button
            type="default"
            v-if="isGranted('Pages.Company.Edit')"
            @click="createOrEdit(2)"
          >
            <a-icon type="edit" />
            {{ l("Edit") }}
          </a-button> -->
          <a-button
            type="danger"
            @click="deleteFileld()"
          >
            <a-icon type="delete" />
            删除
          </a-button>
        </a-col>
      </a-row>

      <!-- table -->
      <!-- <list-table
        :table-data="tableData"
        :total-items="totalItems"
        :tableKey="tableKey"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        @emitEdit="edit"
        @emitDeleteItem="deleteItem"
        @emitRefreshData="getData"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
      </list-table> -->
      <a-table
        class="list-table"
        @change="sortData"
        :pagination="false"
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="tableData"
        :rowKey="(tableDatas) => tableDatas.id"
        selectedRowKeys
      >
        <span slot="actions" slot-scope="text, record">
          <a class="table-edit" @click="createOrEdit(record)">
            <a-icon type="edit" /> 修改
          </a>
        </span>
      </a-table>
    </a-card>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CollectionFormServiceProxy } from "./services/collectionform-proxies";
import moment from "moment";
import { ListTable } from "@/components";
import EditForm from "./create-or-edit-field.vue";
export default {
  mixins: [ModalComponentBase],
  name: "fieldmanageform",
  data() {
    return {
      spinning: false,
      // 搜索
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      _serviceProxy: "",
      commonService: null,
      columns: [
        {
          title: "字段类型",
          dataIndex: "itemTypeStr",
          scopedSlots: { customRender: "itemType" },
        },
        {
          title: "字段是否必填",
          dataIndex: "isRequireStr",
          scopedSlots: { customRender: "isRequire" },
        },
        {
          title: "字段标题",
          dataIndex: "name",
        },
        {
          title: "字段选项内容",
          dataIndex: "options",
        },
        {
          title: "字段描述",
          dataIndex: "remark",
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
    };
  },
  components: { ListTable },
  created() {
    console.log(this);
    this._serviceProxy = new CollectionFormServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.getData();
  },
  methods: {
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      let id = this._props.modelData.id;
      var response = await this._serviceProxy.getFormItem(id);
      this.spinning = false;
      response.map((item) => {
        // if(item.options){
        //           var options = JSON.parse(item.options);
        // item.options = options.forEach(m=>m.value).join(",");
        // }
        item.options = item.options.map(m=>m.value).join(",");
        item.isRequireStr = item.isRequire ? "必填" : "不必填";
        item.itemTypeStr = ["文本","多行文本","数值","选择框","单选框","多选框","开关","上传"][item.itemType-1];
      });
      if(this.filterText){
              response = response.filter(m=>m.name.indexOf(this.filterText)!=-1);
      }

      this.tableData = response;
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },

    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = "";
      this.getData();
    },
    /**
     * 新建修改
     */
    createOrEdit(item) {
      // console.log(this.selectedRows);
      // if (type === 2) {
      //   const selectCount = this.selectedRows.length;
      //   if (selectCount != 1) {
      //     abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "编辑"));
      //     return;
      //   }
      // }
      // let id = (type==2? this.selectedRows[0].id:"");
      let id = item ? item.id : "";
      let formId = this._props.modelData.id;
      ModalHelper.create(
        EditForm,
        {
          id: id,
          formId: formId,
        },
        {
          width: "600px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    deleteFileld(id) {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "删除"));
        return;
      }
      this.message.confirm("是否确定删除", (res) => {
        if (res) {
          let id = this.selectedRows[0].id;
          this.spinning = true;
          this._serviceProxy
            .removeItem(id)
            .finally(() => {
              this.spinning = false;
            })
            .then(() => {
              this.refreshGoFirstPage();
              this.$notification["success"]({
                message: this.l("SuccessfullyDeleted"),
              });
            });
        }
      });
    },
    /**
     * 清空
     */
    restCheckStatus() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    sortData() {},
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
        },
      };
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin-bottom: 20px;
}
.list-table {
  border: 1px solid #e8e8e8;
  // margin: 20px;
  // padding: 20px;
  .table-edit {
    margin-left: 10px;
    // margin-right: 10px;
  }
}
</style>
