import moment from "moment";


export class CollectionFormListDto implements ICollectionFormListDto {
  /** 表单名称 */
  formName: string | undefined;
  /** 顶部描述 */
  headerRemark: string | undefined;
  /** 底部描述 */
  footerRemark: string | undefined;
  /** 表单类型   */
  formType: number | undefined;
  /** 表单状态 */
  formStatus: number | undefined;
  /** 周期 */
  cycleType: number | undefined;
  /** 频次  0  就是无限次 */
  frequency: number | undefined;
  /** 收集开始时间 */
  startTime: moment.Moment | undefined;
  /** 收集结束时间 */
  endTime: moment.Moment | undefined;
  creationTime:moment.Moment | undefined;
  projectName:string | undefined;
  collectionFormId:string;
  id: string;

  constructor(data?: CollectionFormListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.formName = _data["formName"];
      this.headerRemark = _data["headerRemark"];
      this.footerRemark = _data["footerRemark"];
      this.formType = _data["formType"];
      this.formStatus = _data["formStatus"];
      this.cycleType = _data["cycleType"];
      this.frequency = _data["frequency"];
      this.startTime = _data["startTime"]
        ? moment(_data["startTime"].toString())
        : <any>undefined;
      this.endTime = _data["endTime"]
        ? moment(_data["endTime"].toString())
        : <any>undefined;
      this.id = _data["id"];
      this.projectName = _data["projectName"];
      this.creationTime = _data["creationTime"];
      this.collectionFormId=_data["collectionFormId"]
    }
  }

  static fromJS(data: any): CollectionFormListDto {
    data = typeof data === "object" ? data : {};
    let result = new CollectionFormListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["formName"] = this.formName;
    data["headerRemark"] = this.headerRemark;
    data["footerRemark"] = this.footerRemark;
    data["formType"] = this.formType;
    data["formStatus"] = this.formStatus;
    data["cycleType"] = this.cycleType;
    data["frequency"] = this.frequency;
    data["startTime"] = this.startTime
      ? this.startTime.toISOString()
      : <any>undefined;
    data["endTime"] = this.endTime
      ? this.endTime.toISOString()
      : <any>undefined;

    data["id"] = this.id;
    return data;
  }

  clone(): CollectionFormListDto {
    const json = this.toJSON();
    let result = new CollectionFormListDto();
    result.init(json);
    return result;
  }
}
export interface ICollectionFormListDto {
  /** 表单名称 */
  formName: string | undefined;
  /** 顶部描述 */
  headerRemark: string | undefined;
  /** 底部描述 */
  footerRemark: string | undefined;
  /** 表单类型   */
  formType: number | undefined;
  /** 表单状态 */
  formStatus: number | undefined;
  /** 周期 */
  cycleType: number | undefined;
  /** 频次  0  就是无限次 */
  frequency: number | undefined;

  /** 收集开始时间 */
  startTime: moment.Moment | undefined;
  /** 收集结束时间 */
  endTime: moment.Moment | undefined;

  id: string;
}

export class PagedResultDtoOfFormListDto
  implements IPagedResultDtoOfFormListDto {
  totalCount: number;
  items: CollectionFormListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfFormListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.totalCount = _data["totalCount"];
      if (Array.isArray(_data["items"])) {
        this.items = [] as any;
        for (let item of _data["items"]) {
          this.items.push(CollectionFormListDto.fromJS(item));
        }
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfFormListDto {
    data = typeof data === "object" ? data : {};
    let result = new PagedResultDtoOfFormListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["totalCount"] = this.totalCount;
    if (Array.isArray(this.items)) {
      data["items"] = [];
      for (let item of this.items) {
        data["items"].push(item.toJSON());
      }
    }
    return data;
  }

  clone(): PagedResultDtoOfFormListDto {
    const json = this.toJSON();
    let result = new PagedResultDtoOfFormListDto();
    result.init(json);
    return result;
  }
}

export interface IPagedResultDtoOfFormListDto {
  totalCount: number;
  items: CollectionFormListDto[] | undefined;
}
export class CollectionFormItemListDto{
  
}
export class CollectionFormItemDto {
  private Name: string;
  private ItemType: string;
  private Options: string;
  private IsRequire: string;
  private Status: string;
  private Remark: string;
  private CollectionFormId: string;

  constructor () {
    
  }

  toJson(): any {
      var json = {
        Name: this.Name,
        ItemType: this.ItemType,
        Options: this.Options,
        IsRequire: this.IsRequire,
        Status: this.Status,
        Remark: this.Remark,
        CollectionFormId: this.CollectionFormId,
      };
      return json;
  }

  formJson (json?: any): CollectionFormItemDto {
      console.info(json);
      let Name = json["Name"];
      let ItemType = json["ItemType"];
      let Options = json["Options"];
      let IsRequire = json["IsRequire"];
      let Status = json["Status"];
      let Remark = json["Remark"];
      let CollectionFormId = json["CollectionFormId"];
      this.Name = Name;
      this.ItemType = ItemType;
      this.Options = Options;
      this.IsRequire = IsRequire;
      this.Status = Status;
      this.Remark = Remark;
      this.CollectionFormId = CollectionFormId;
      return this;
  }
}
