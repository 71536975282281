<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="id">编辑字段</span>
        <span v-if="!id">添加字段</span>
      </div>
    </div>

    <a-form
      :form="form"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      @submit="handleSubmit"
    >
      <a-form-item label="字段类型" has-feedback>
        <a-select
          v-decorator="[
            'itemType',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        >
          <!-- <a-select-option key="" value="文本">文本</a-select-option>
          <a-select-option key="" value="单选">单选</a-select-option>
          <a-select-option key="" value="多选">多选</a-select-option> -->
          <a-select-option
            v-for="(item, index) in itemTypeList"
            :key="index"
            :value="item.code"
            @click="handleChange(item.code)"
            >{{ item.text }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="字段是否必填" has-feedback>
        <a-select
          v-decorator="[
            'isRequire',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        >
          <!-- <a-select-option key="" value="必填">必填</a-select-option>
          <a-select-option key="" value="不必填">不必填</a-select-option> -->
          <a-select-option
            v-for="(item, index) in requireList"
            :key="index"
            :value="item.code"
            >{{ item.text }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="字段标题" has-feedback>
        <a-input
          placeholder="请输入字段标题"
          v-decorator="[
            'name',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="字段标识" has-feedback>
        <a-input
          placeholder="请输入字段标识"
          v-decorator="[
            'key',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
                {
                  validator: checkData,
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="字段最大长度" has-feedback>
        <a-input-number
          :min="0"
          placeholder="请输入字段最大长度"
          v-decorator="[
            'maxLength',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <!-- <a-form-item label="字段选项内容" has-feedback v-if="optionsShow">
        <a-textarea
          placeholder="请输入字段选项内容"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          v-decorator="[
            'options',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        />
      </a-form-item> -->
      <!-- 类型为Select Checkbox Radio 添加Options -->
      <a-form-item label="字段选项内容" v-if="optionsShow">
        <div>
          <template v-for="(tag, index) in tags">
            <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
              <a-tag
                :key="tag"
                :closable="index !== -1"
                @close="() => handleClose(tag)"
              >
                {{ `${tag.slice(0, 20)}...` }}
              </a-tag>
            </a-tooltip>
            <a-tag
              color="blue"
              v-else
              :key="tag"
              :closable="index !== -1"
              @close="() => handleClose(tag)"
            >
              {{ tag }}
            </a-tag>
          </template>
          <a-input
            v-if="inputVisible"
            ref="input"
            type="text"
            size="small"
            :style="{ width: '78px' }"
            :value="inputValue"
            @change="handleInputChange"
            @blur="handleInputConfirm"
            @keyup.enter="handleInputConfirm"
          />
          <a-tag
            v-else
            style="background: #fff; borderstyle: dashed"
            @click="showInput"
          >
            <a-icon type="plus" /> 添加值
          </a-tag>
        </div>
      </a-form-item>
      <a-form-item label="字段描述" has-feedback>
        <a-textarea
          placeholder="请输入字段描述"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          v-decorator="[
            'remark',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <!-- <a-form-item label="排序" has-feedback>
        <a-input
          placeholder="请输入排序号"
          v-decorator="[
            'Sort',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        />
      </a-form-item> -->
      <a-form-item class="btn--container">
        <a-button type="button" @click="close()">
          {{ l("Cancel") }}
        </a-button>
        <a-button type="primary" html-type="submit">
          {{ l("Save") }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CollectionFormServiceProxy } from "./services/collectionform-proxies";
import moment from "moment";
export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-field",
  data() {
    return {
      // 获取到的数据
      entity: {},
      spinning: false,
      _serviceProxy: "",
      labelCol: {
        span: 5,
      },
      requireList: [
        { code: true, text: "必填" },
        { code: false, text: "不必填" },
      ],
      itemTypeList: [
        { code: 1, text: "文本" },
        { code: 2, text: "多行文本" },
        { code: 3, text: "数值" },
        { code: 4, text: "选择框" },
        { code: 5, text: "单选框" },
        { code: 6, text: "多选框" },
        { code: 7, text: "开关" },
        { code: 8, text: "上传" },
      ],
      optionsShow: false,
      tags: [],
      inputVisible: false,
      inputValue: "",
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this._serviceProxy = new CollectionFormServiceProxy(
      this.$apiUrl,
      this.$api
    );
  },
  async mounted() {
    console.log(this);
    if (this.id) {
      await this.getData();
    } else {
      this.form.setFieldsValue({
        itemType: 1,
        isRequire: true,
      });
    }
  },
  methods: {
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      var res = await this._serviceProxy.getFormItem(
        this._props.modelData.formId
      );
      this.spinning = false;
      // res.IsRequire = res.IsRequire?"必填":"不必填"
      console.log("res" + JSON.stringify(res));
      let obj = res.find((m) => m.id == this._props.modelData.id);
      console.log(obj);
      if (obj.options.length) {
        this.tags = obj.options.map((m) => m.value);
      }
      // obj.isRequire = obj.isRequire ? "必填" : "不必填";
      // obj.itemType = ["文本", "单选", "多选"][obj.itemType - 1];
      this.handleChange(obj.itemType);
      this.form.setFieldsValue({
        ...obj,
        id: this.id,
      });
    },
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        console.log(err);
        console.log(values);
        if (!err) {
          try {
            if (
              values.itemType == 4 ||
              values.itemType == 5 ||
              values.itemType == 6
            ) {
              if (this.tags.length <= 0) {
                this.message.error("字段选项内容不能为空");
                return false;
              }
            }
            this.spinning = true;
            if (!this._props.modelData.id) {
              let obj = {};
              let arr = [];
              let tagArr = [];
              this.tags.forEach((item) => {
                tagArr.push({
                  label: item,
                  value: item,
                });
              });
              values.options = JSON.stringify(tagArr);
              obj.FormId = this._props.modelData.formId;
              // values.itemType =
              //   values.itemType == "文本"
              //     ? 1
              //     : values.itemType == "单选"
              //     ? 2
              //     : 3;
              // values.isRequire = values.isRequire == "必填" ? true : false;
              arr.push(values);
              obj.FormItemEdits = arr;
              const body = JSON.stringify(obj);
              console.log(body);
              let res = await this._serviceProxy.addItem(body);
              this.spinning = false;
              console.log(res);
              if (res.status == 200) {
                this.$notification["success"]({
                  message: this.l("SavedSuccessfully"),
                });
                this.success(true);
              }
            } else {
              if (
                values.itemType == 4 ||
                values.itemType == 5 ||
                values.itemType == 6
              ) {
                let tagArr = [];
                this.tags.forEach((item) => {
                  tagArr.push({
                    label: item,
                    value: item,
                  });
                });
                values.options = tagArr;
              } else {
                values.options = [];
              }

              values.id = this._props.modelData.id;
              values.collectionFormId = this._props.modelData.formId;
              const body = JSON.stringify(values);
              console.log(body);

              let res = await this._serviceProxy.updateItem(body);
              this.spinning = false;
              console.log(res);
              if (res.status == 200) {
                this.$notification["success"]({
                  message: this.l("SavedSuccessfully"),
                });
                this.success(true);
              }
            }
          } catch {
            this.spinning = false;
          }
        }
      });
    },
    handleChange(code) {
      if (code == 4 || code == 5 || code === 6) {
        this.optionsShow = true;
      } else {
        this.optionsShow = false;
        // this.form.setFieldsValue({
        //   options: "",
        // });
      }
    },
    // 自定义校验方法， 输入框不能输入汉字
    checkData(rule, value, callback) {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value) || /^[0-9]+.?[0-9]*$/g.test(value)) {
          callback(new Error("只可输入字母、不能输入汉字!"));
        }
      }
      callback();
    },
    //标签操作
    handleClose(removedTag) {
      const tags = this.tags.filter((tag) => tag !== removedTag);
      console.log(tags);
      this.tags = tags;
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },

    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      let tags = this.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      console.log(tags);
      Object.assign(this, {
        tags,
        inputVisible: false,
        inputValue: "",
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
  display: block;
  margin: 10px auto;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
